import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Dcontent from "./components/dashboard/content/Dcontent";


const Home = lazy(() => import("./pages/home/Home"));
const Login = lazy(() => import("./pages/login/Login"));
const Shop = lazy(() => import("./pages/shop/Shop"));
const Team = lazy(() => import("./pages/team/Team"));
const Earn = lazy(() => import("./pages/earn/Earn"));
const About = lazy(() => import("./pages/about/About"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Features = lazy(() => import("./pages/features/Features"));
const CHSCard = lazy(() => import("./pages/chscard/CHSCard"));
const Cart = lazy(() => import("./pages/cart/Cart"));
const Checkout = lazy(() => import("./pages/checkout/Checkout"));

const Dashboard = lazy(() => import("./dashboard/home/Home"));
const Wallet = lazy(() => import("./dashboard/wallet/Wallet"));
const Referral = lazy(() => import("./dashboard/referral/Referral"));
const Groupsell = lazy(() => import("./dashboard/groupsell/Groupsell"));
const Pr = lazy(() => import("./dashboard/pr/Pr"));
const Pi = lazy(() => import("./dashboard/pi/Pi"));
const Generation = lazy(() => import("./dashboard/generation/Generation"));
const Generation1 = lazy(() => import("./dashboard/generation/Generation1"));
const SalesRoyality = lazy(() =>
  import("./dashboard/salesroyality/SalesRoyality")
);
const SalesRoyality1 = lazy(() =>
  import("./dashboard/salesroyality/SalesRoyality1")
);
const ShopReferral = lazy(() =>
  import("./dashboard/shopreferral/ShopReferral")
);
const ShopPi = lazy(() => import("./dashboard/shoppi/ShopPi"));
const Teams = lazy(() => import("./dashboard/team/Team"));
const Add = lazy(() => import("./dashboard/addteam/Add"));
const Transfer = lazy(() => import("./dashboard/transfer/Transfer"));
const Promotionalstatus = lazy(() =>
  import("./dashboard/promotionalstatus/Promotionalstatus")
);
const Convert = lazy(() => import("./dashboard/convert/Convert"));
const Withdraw = lazy(() => import("./dashboard/withdraw/Withdraw"));
const Withdrawhistory = lazy(() =>
  import("./dashboard/withdrawhistory/Withdrawhistory")
);
const Converthistory = lazy(() =>
  import("./dashboard/converthistory/Converthistory")
);
const Shopdashboard = lazy(() => import("./dashboard/shop/Shop"));
const Orders = lazy(() => import("./dashboard/orders/Orders"));
const Profile = lazy(() => import("./dashboard/profile/Profile"));

function App() {
  return (
    <Suspense fallback={'Loading...'}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/team" element={<Team />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/features" element={<Features />} />
        <Route path="/chs-card" element={<CHSCard />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/dashboard" element={<Dcontent />}>
          <Route index element={<Dashboard />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="referral-earnings" element={<Referral />} />
          <Route path="group-sell" element={<Groupsell />} />
          <Route path="positional-rewards" element={<Pr />} />
          <Route path="pi" element={<Pi />} />
          <Route path="generations" element={<Generation />} />
          <Route path="generation-1" element={<Generation1 />} />
          <Route path="generation-2" element={<Generation1 />} />
          <Route path="generation-3" element={<Generation1 />} />
          <Route path="generation-4" element={<Generation1 />} />
          <Route path="generation-5" element={<Generation1 />} />
          <Route path="sales-royality-income" element={<SalesRoyality />} />
          <Route path="s-gen-1" element={<SalesRoyality1 />} />
          <Route path="s-gen-2" element={<SalesRoyality1 />} />
          <Route path="s-gen-3" element={<SalesRoyality1 />} />
          <Route path="s-gen-4" element={<SalesRoyality1 />} />
          <Route path="s-gen-5" element={<SalesRoyality1 />} />
          <Route path="shop-refer" element={<ShopReferral />} />
          <Route path="shop-pi" element={<ShopPi />} />
          <Route path="team/:id" element={<Teams />} />
          <Route path="team" element={<Teams />} />
          <Route path="add-team" element={<Add />} />
          <Route path="add-team/:id" element={<Add />} />
          <Route path="transfer" element={<Transfer />} />
          <Route path="team-promotions" element={<Promotionalstatus />} />
          <Route path="convert" element={<Convert />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="withdraw-history" element={<Withdrawhistory />} />
          <Route path="convert-history" element={<Converthistory />} />
          <Route path="shop" element={<Shopdashboard />} />
          <Route path="orders" element={<Orders />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
